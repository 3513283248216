import React from "react";
import "./Home.css";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import Newsletter from "./Newsletter";
import Categories from './Categories';
import Content from './Content';
import ProductCategories from "../../../components/products/ProductCategories";
import BannerLogo from "../../../components/bannerlogo/BannerLogo";

export default function Home(props) {
    return (
        <div className="home">
            <Header />

            <Content />
            <Newsletter />
            <ProductCategories />
            <Categories />

            <BannerLogo />
            <Footer />
        </div>
    );
}
