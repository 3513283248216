import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import BannerLogo from "../../../components/bannerlogo/BannerLogo";

export default function Newsletter() {

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const emailValue = event.target.newsletter.value;

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/newsletters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });

            if (response.ok) {
                setEmail(emailValue);
                setMessage('Email enviado com sucesso: ' + emailValue);
                setOpen(true);
            } else {
                setMessage('Falha ao enviar o email');
                setOpen(true);
            }
        } catch (error) {
            setMessage('Ocorreu um erro');
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Header />
            <Container component="section" sx={{ mt: 10, display: 'flex' }}>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                bgcolor: 'rgba(0, 0, 0, 0.12)',
                                py: 8,
                                px: 3,
                            }}
                        >
                            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
                                <Typography variant="h2" component="h2" gutterBottom>
                                    Gosta de novidades?
                                </Typography>
                                <Typography variant="h5">
                                    Assine nossa Newsletter e fique atualizado.
                                </Typography>
                                <TextField
                                    noBorder
                                    placeholder="Digite seu email"
                                    id="newsletter"
                                    variant="standard"
                                    sx={{ width: '100%', mt: 3, mb: 2 }}
                                />
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    sx={{ width: '100%' }}
                                >
                                    Me mantenha atualizado
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
                    >
                        <Box
                            component="img"
                            src="https://www.digitaltrends.com/wp-content/uploads/2023/06/Apple-Vision-Pro-provides-virtual-screens-for-your-Mac.jpg?fit=720%2C480&p=1"
                            alt="call to action"
                            sx={{
                                position: 'absolute',
                                top: -28,
                                left: -28,
                                right: 0,
                                bottom: 0,
                                width: '100%',
                                maxWidth: 600,
                            }}
                        />
                    </Grid>
                </Grid>
                <Snackbar
                    open={open}
                    onClose={handleClose}
                    message={message}
                    autoHideDuration={6000}
                />
            </Container>
            <BannerLogo />
            <Footer />
        </>
    );
}
