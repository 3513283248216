import * as React from 'react';
import "./Courses.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Course from './Course';

import { useState, useEffect } from 'react';

import MyMenu from '../mymenu/MyMenu';
import Footer from "../footer/Footer";
import BannerLogo from "../bannerlogo/BannerLogo";

const goToMyCourses = () => {
    window.open("/mycourses");
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Courses() {

    const [coursesData, setCoursesData] = useState([]);
    const url = process.env.REACT_APP_API_URL + '/api/v1/courses';

    const token = document.cookie.split('; ').find(row => row.startsWith('session=')).split('=')[1];

    useEffect(() => {
        const fetchCoursesData = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Adicionando o token JWT aos cabeçalhos
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const json = await response.json();
                const { courses } = json;
                // Only put the results in state, i.e., the actual courses array
                setCoursesData(courses || []); // Set an empty array if results is null or undefined
            } catch (error) {
                console.log("Error to Fetch:", error.message);
                setCoursesData([]); // Set an empty array in case of error
            }
        };
        fetchCoursesData();
    }, [url, token]); // Empty dependency array ensures the effect runs only once, like componentDidMount

    return (
        <ThemeProvider theme={defaultTheme}>
            <MyMenu />
            <Box sx={{ display: 'flex' }}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <h1>Cursos</h1>

                                    <p>Aqui você encontra nossos cursos</p>

                                    <Button variant="contained" onClick={goToMyCourses}
                                            sx={{
                                                // size
                                                width: 300,
                                                // center
                                                margin: '0 auto',
                                                backgroundColor: '#4caf50',
                                                '&:hover': {
                                                    backgroundColor: '#388e3c',
                                                    opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}
                                    >
                                        Ir para Meus Cursos
                                    </Button>

                                    <br />
                                    <br />
                                    <Grid container spacing={3}>
                                        {coursesData.length === 0 ? (
                                            <Grid item xs={12}>
                                                <p>Nosso marketplace ainda não tem nenhum curso a venda.</p>
                                            </Grid>
                                        ) : (
                                            coursesData.map((course) => (
                                                <Grid item xs={12} md={4} lg={4} key={course.id}>
                                                    <Course course={course} />
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>

                                </Paper>
                            </Grid>
                        </Grid>
                        {/* <Footer /> */}
                    </Container>
                    <BannerLogo />
                    <Footer />
                </Box>
            </Box>
        </ThemeProvider>
    );
}
