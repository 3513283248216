import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.lideratech.com.br/">
        lideratech.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
// Align fotter in Middle
const defaultTheme = createTheme();

export default function Footer() {
  return (
      <div className="footer">
          <ThemeProvider theme={defaultTheme}>
              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}
              >
                  <Container maxWidth="sm">
                      <Typography variant="body1" align="center">
                          <br/>
                          <a className="hrefWithBackgroundWhite"
                             href="mail:contato@lideratech.com.br"> contato@lideratech.com.br</a>
                          <br/>
                      </Typography>
                      <Copyright/>
                      <br/>
                      <br/>
                      <br/>
                  </Container>
              </Box>
          </ThemeProvider>

      </div>
  );
}