import React, { useEffect, useState } from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Linkbar from "../../../components/linkbar/Linkbar";
// import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { getAuth } from 'firebase/auth';
import Cookies from 'js-cookie'; // Importa Cookies corretamente

const getUserInfo = async () => {
    const token = Cookies.get("session"); // Obtém o token do cookie

    if (token) {
        const auth = getAuth();
        try {
            const user = auth.currentUser; // Não precisa de 'await' para auth.currentUser
            if (user) {
                console.log("Informações do usuário:", user);
                return user;
            } else {
                console.log("Nenhum usuário");
                return null;
            }
        } catch (error) {
            console.log("Erro ao verificar o token", error);
            return null;
        }
    }
    return null;
};

export default function MyProfile() {
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');

    useEffect(() => {
        async function fetchUserInfo() {
            const userInfo = await getUserInfo();
            if (userInfo) {
                setUser(userInfo);

                // Obtendo o email do JWT armazenado no cookie
                const cookie = document.cookie.split('; ').find(row => row.startsWith('session='));
                if (cookie) {
                    const base64Url = cookie.split('=')[1].split('.')[1]; // Parte do payload do JWT
                    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Ajuste de Base64
                    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));
                    const parsedPayload = JSON.parse(jsonPayload);
                    setEmail(parsedPayload.email);
                }
            }
        }
        fetchUserInfo();
    }, []);

    // const navigate = useNavigate();

    if (!user) {
        return <div>Carregando...</div>;
    }

    return (
        <>
            <Header />
            <Linkbar />
            <div>
                <h1>Profile</h1>
                <p>My Profile List</p>
            </div>

            <Paper
                sx={{
                    p: 5,
                    margin: 'auto',
                    maxWidth: 1200,
                }}
            >
                <TextField
                    disabled
                    id="email"
                    label="Email"
                    value={email || 'No email found'}  // Exibindo o email no campo ou uma mensagem padrão
                    variant="standard"
                />
            </Paper>

            <Footer />
        </>
    );
}

