import React from "react";
import Button from "@mui/material/Button";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";


export default function NotFound() {
    return (
        <>
            <Header />
            <div>
                <h1>Uai... <br />Acho que essa página não existe...</h1>
                <p>Melhor voltar e começar de novo...</p>
                <Button variant="contained" color="primary" href="/">
                    Voltar para a página inicial
                </Button>
            </div>
            <Footer />
        </>
    )
}