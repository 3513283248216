import React from "react";
import "./Header.css";

import MyMenu from "../mymenu/MyMenu";

export default function Header() {
  return (
      <>
          <MyMenu />
          <div className="header">
          </div>
      </>
  );
}
