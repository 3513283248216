import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthAction({ children }) {
    const [globalVariable, setGlobalVariable] = useState(null);

    const setGlobalContent = (content) => {
        setGlobalVariable(content);
    };

    return (
        <AuthContext.Provider value={{ globalVariable, setGlobalContent }}>
            {children}
        </AuthContext.Provider>
    );
}