import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import BannerLogo from '../../../components/bannerlogo/BannerLogo';

const StyledItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    textAlign: 'center',
}));

const Item = ({ name, description, link }) => (
    <Grid item>
        <StyledItem>
            {/*title*/}
            <b>{name}</b>
            <br/>
            {description}
            <br/>
            <br/>
            {/*btn btn-dark*/}
            <Button variant="contained" color="inherit" href={link}>
                Clique aqui
            </Button>

        </StyledItem>
    </Grid>
);

export default function Dashboard() {

    const MenuItem1 = {
        name: "Produtos",
        description: "Produtos disponíveis para compra.",
        link: "/myproducts"
    }
    const MenuItem2 = {
        name: "Newsletter",
        description: "Assine nossa newsletter e fique por dentro.",
        link: "/mynewsletter"
    }
    const MenuItem3 = {
        name: "Meus Cursos",
        description: "Assista seus cursos já comprados.",
        link: "/mycourses"
    }
    const MenuItem4 = {
        name: "Meus eBooks",
        description: "Leia os eBooks já comprados.",
        link: "/myebooks"
    }

    const MenuItems = new Set([MenuItem1, MenuItem2, MenuItem3, MenuItem4]);

    const produtcs = [
        {
            id: 1,
            name: 'Conheça Go ou Golang',
            description: 'É normal achar que nunca vai aprender programação e focar somente em Shell Script, mas Go veio para mudar isso.',
            price: 100.00,
            path: 'https://thomacelli.medium.com/1-dando-o-primeiro-passso-na-programa%C3%A7%C3%A3o-com-go-4f2a795c26df',
            image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*HsoBIk6n0eITpqlL2Ht9hw.png'
        },
        {
            id: 2,
            name: 'Soft Skills é importante?',
            description: 'Cada vez mais vemos falar sobre Soft Skills, mas pouco é ensinado dentro das empresas.',
            price: 200.00,
            path: 'https://thomacelli.medium.com/soft-skill-reprogramando-a-mente-como-um-programador-iniciante-5f4cc37cf25f',
            image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*Qo5mHXJzDvlNOX0tTIZoYQ.png'
        },
        {
            id: 3,
            name: 'Alta Performance em Tecnologia?',
            description: 'Será que conseguimos alcançar alta performance?',
            price: 9.90,
            path: 'https://www.linkedin.com/pulse/criando-times-de-alta-performance-antonio-thomacelli-gomes/',
            image: 'https://media.licdn.com/dms/image/C4D12AQH3485E83KPgg/article-cover_image-shrink_720_1280/0/1591573214491?e=1723075200&v=beta&t=QZ2XAIo2AW9N4EbTOV8loS9xN_-y-qsi3RZ1ducKc3Q'
        },
    ]

    return (
        <>
            <Header />
            <div>
                <h1>Dashboard</h1>
                <Paper
                    sx={{
                        p: 5,
                        margin: 'auto',
                        maxWidth: 1200,
                    }}
                >
                    <Grid container spacing={{xs: 2, sm: 2, md: 4, lg: 8}} columns={{xs: 2, sm: 4, md: 6, lg: 8}}>
                        {Array.from(MenuItems).map((_, index) => (
                            <Grid item xs={2} sm={2} md={2} key={index}>
                                <Item name={_.name} description={_.description} link={_.link}/>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>

                <br/>
                <br/>
                <h1>Noticias</h1>
                <Paper
                    sx={{
                        p: 5,
                        margin: 'auto',
                        maxWidth: 1200,
                    }}
                >
                    <Grid container spacing={10}>
                        {produtcs.map((product) => (
                            <Grid item key={product.id} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardMedia
                                        component="div"
                                        sx={{
                                            // 16:9
                                            pt: '56.25%',
                                        }}
                                        image={product.image}
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {product.name}
                                        </Typography>
                                        <Typography>
                                            {product.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={() => window.location
                                            .assign(product.path)}>
                                            Ver mais
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </div>
            <BannerLogo />
            <Footer />
        </>
    )
}
