import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

const defaultTheme = createTheme();

export default function BannerLogo() {
  return (
      <div className="footer">
          <ThemeProvider theme={defaultTheme}>
              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}
              >
                  <a href="https://www.lideratech.com.br">
                  <img
                      src="/static/images/4.png"
                      alt="LideraTech"
                      width="240"
                      height="240"
                  />
                  </a>
              </Box>
          </ThemeProvider>
      </div>
  );
}