import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const produtcs = [
    {
        id: 1,
        name: 'Newsletter',
        description: 'Nos acompanhe e fique por dentro do que faz sendito de forma gratuita.',
        price: 100.00,
        path: '/newsletter',
        image: 'https://thumbs.dreamstime.com/z/internet-marketing-line-style-business-web-online-media-network-technology-advertising-vector-illustration-62191433.jpg'
    },
    {
        id: 2,
        name: 'Cursos',
        description: 'Cursos alinhados com tendências de mercado e que fazem sentido.',
        price: 200.00,
        path: '/courses',
        image: 'https://thumbs.dreamstime.com/z/training-coaching-chart-keywords-icons-44364874.jpg'
    },
    {
      id: 3,
      name: 'eBooks',
      description: 'Criamos alguns eBooks que podem te ajudar a evoluir seu aprendizado.',
      price: 9.90,
      path: '/ebooks',
      image: 'https://thumbs.dreamstime.com/z/ebook-concept-coffee-book-top-view-lorem-ipsum-text-used-61896795.jpg'
  },    
    // {
    //     id: 3,
    //     name: 'APIs',
    //     description: 'APIs que ajudam seu produto a crescer.',
    //     price: 300.00,
    //     image: 'https://thumbs.dreamstime.com/b/api-application-programming-interface-concept-tablet-phone-computer-smart-215170982.jpg'
    // },    
]

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Album() {

  const goPage = (path, id) => {
      console.log('goPage', path, id);
      window.location.href = path;
  }

  return (
      <div className="products">
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <main>
          {/* Hero unit */}
          <Box
            sx={{
              bgcolor: 'background.paper',
              pt: 8,
              pb: 6,
            }}
          >
            <Container maxWidth="sm">
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
              >
              Nossos Produtos
              </Typography>
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Aqui você encontra algum de nossos produtos e serviços.
              </Typography>
            </Container>
          </Box>
          <Container sx={{ py: 8 }} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {produtcs.map((product) => (
                  <Grid item key={product.id} xs={12} sm={6} md={4}>
                      <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                      >
                      <CardMedia
                          component="div"
                          sx={{
                          // 16:9
                          pt: '56.25%',
                          }}
                          image={product.image}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                          <Typography gutterBottom variant="h5" component="h2">
                          {product.name}
                          </Typography>
                          <Typography>
                          {product.description}
                          </Typography>
                      </CardContent>
                      <CardActions>
                          <Button size="small" onClick={() => goPage(product.path, product.id)}>Saiba mais...</Button>
                      </CardActions>
                      </Card>
                  </Grid>
                  ))}
            </Grid>
          </Container>
        </main>
      </ThemeProvider>
    </div>
  );
}