import React from "react";
import "./Products.css";
import MyMenu from "../mymenu/MyMenu";
import ProductCategories from "./ProductCategories";
import Footer from "../footer/Footer";
import BannerLogo from "../bannerlogo/BannerLogo";

export default function Products(props) {
    return (
        <div className="products">
            <MyMenu />
            <ProductCategories />
            <BannerLogo />
            <Footer />
        </div>
    );
}
