import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import * as Cookies from "js-cookie";

export const getSessionCookie = () => {
    const sessionCookie = Cookies.get("session");
  
    if (sessionCookie === undefined) {
      return "";
    } else {
      return sessionCookie;
    }
  };

const AuthRequired = () => {

    let authTemp = {'token': false}

    const session = getSessionCookie();
    // validate token in firebase
    if (session) {
        authTemp.token = true;
        // console.log("session:", session);
    } else {
        authTemp.token = false;
    }

    return(
        authTemp.token ? <Outlet/> : <Navigate to="/login" />
    )
}

export function AuthCheckValidToken() {

  let authCheckValidToken = {'token': false}

  const session = getSessionCookie();

  if (session) {

    // Get from env var API_URL
    const urlCore = process.env.REACT_APP_API_URL;

    // use axios to make POST request and create a valid token in backend to store in cookie
    // axios must send the cookie session to backend can check if is valid in 
    const axios = require('axios');

    // cors allow all
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

    axios.post(urlCore + '/auth/valid-token', {
      token: session
    })
    .then(function (response) {
      // handle success
      console.log(response);
      authCheckValidToken.token = true;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      authCheckValidToken.token = false;
    })
  }
  return authCheckValidToken.token;
}

export default AuthRequired
