import React from 'react';

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Linkbar from "../../../components/linkbar/Linkbar";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import BannerLogo from '../../../components/bannerlogo/BannerLogo';

export default function MyProducts() {

    const navigate = useNavigate();

    return (
        <>
            <Header />
            <Linkbar />
            <div>
                <h1>Produtos</h1>
                <p>Marketplace de Produtos</p>
            </div>

            <Paper
                sx={{
                    p: 5,
                    margin: 'auto',
                    maxWidth: 1200,
                }}
            >
                {/*Acesse os cursos https://www.lideratech.com.br/courses*/}
                <Button id="free-course" onClick={() => navigate('/courses')} variant="contained" color="primary">Cursos</Button>
                <span>&nbsp;</span>
                <Button id="free-course" onClick={() => navigate('/ebooks')} variant="contained" color="primary">eBooks</Button>

            </Paper>


            <BannerLogo />
            <Footer />
        </>
    );
}