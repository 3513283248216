import * as React from 'react';
import "./MyMenu.css";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import * as Cookies from "js-cookie";
import AuthCheck from '../context/AuthCheck';

export const getSessionCookie = () => {
    const sessionCookie = Cookies.get("session");
    return sessionCookie ? sessionCookie : "";
};

const pages = ['Produtos', 'eBooks', 'Cursos', 'Blog'];
const settingsLoggedOut = ['Login', 'Registry'];
const settingsLoggedIn = ['Dashboard', 'Sair'];

const theme = createTheme({
    palette: {
        primary: {
            main: '#212121',
        },
        secondary: {
            main: '#f44336',
        },
    },
});

function MyMenu() {
    const session = getSessionCookie();
    const navigate = useNavigate(); // Usado para navegação

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuClick = (page) => {
        handleCloseNavMenu();
        let optionClicked = `${page.toLowerCase()}`;
        if ( optionClicked === "products" || optionClicked === "produtos" ) {
            navigate('/products');
        } else if ( optionClicked === "ebooks" ) {
            navigate('/ebooks');
        } else if ( optionClicked === "cursos" || optionClicked === "courses" ) {
            navigate('/courses');
        } else {
            navigate(`/${page.toLowerCase()}`);
        }

    };

    const handleUserMenuClick = (option) => {
        handleCloseUserMenu();
        if (option === 'Sair') {
            Cookies.remove("session");
            navigate('/'); // Redireciona para a página de login
        } else {
            navigate(`/${option.toLowerCase()}`);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <AuthCheck />
            <AppBar position="static" color="primary" enableColorOnDark>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <a href="https://www.lideratech.com.br">
                            <img
                                src="/static/images/8.png"
                                alt="LideraTech"
                                width="200"
                                height="60"
                                style={{ marginTop: '10px' }}
                            />
                        </a>

                        {/* Menu para dispositivos móveis */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={() => handleMenuClick(page)}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {/* Menu para dispositivos grandes */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={() => handleMenuClick(page)}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        {/* Menu do usuário */}
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {session ? (
                                        <Avatar alt="A" src="/static/images/avatar/avatar1.jpg" />
                                    ) : (
                                        <Avatar alt="?" src="/static/images/avatar/avatar-unkown.png" />
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {(session ? settingsLoggedIn : settingsLoggedOut).map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleUserMenuClick(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}

export default MyMenu;
