import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB6g9YSStxjTbc8BefUlPOzmbXKVX0hef8",
  authDomain: "lideratech-authentication.firebaseapp.com",
  projectId: "lideratech-authentication",
  storageBucket: "lideratech-authentication.appspot.com",
  messagingSenderId: "806393941814",
  appId: "1:806393941814:web:ab8aab1d0c029134ba2d97",
  measurementId: "G-MJ5WCESHBZ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;