import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Linkbar from '../../../components/linkbar/Linkbar';
import BannerLogo from '../../../components/bannerlogo/BannerLogo';

function CourseDetails() {
    const { id } = useParams();
    const token = document.cookie.split('; ').find(row => row.startsWith('session=')).split('=')[1];

    const [urlVideo, setUrlVideo] = useState('');
    const [content, setContent] = useState(null);
    const [videoTitle, setVideoTitle] = useState("LideraTech Courses");

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/course?courseid=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Adicionando o token JWT aos cabeçalhos
            }
        })
            .then(response => response.json())
            .then(data => {
                setContent(data.content); // Assuming data is structured like { content: {...} }
            })
            .catch(error => {
                console.error('Error fetching course data:', error);
            });
    }, [id, token]);

    console.log("token:", token)
    console.log("content:", content)

    const handleChangeVideo = (url, title) => {
        setUrlVideo(url);
        setVideoTitle(title || "LideraTech Courses");
    }

    if (content === null || content === undefined) {
        return (
            <div>
                <div style={{textAlign: 'center', color: 'gray'}}>
                    <h1>Carregando...</h1>
                    <br />
                    <Button href={'/myCourses'}>Voltar</Button>
                </div>
                <a href={'/myCourses'}><h3>Voltar</h3></a>
            </div>
        )
    }

    if (urlVideo === '') {
        handleChangeVideo(content.chapters[0].videos[0].url, content.chapters[0].videos[0].title)
    }

    return (
        <>
            <Header/>
            <Linkbar/>
            <div>
            <h1>Detalhes do Item</h1>

                <Paper
                    sx={{
                        p: 5,
                        margin: 'auto',
                        maxWidth: 2000,
                    }}
                >
                    <Grid container spacing={10}>
                        <Grid item xs={12} sm={12} md={3}>
                            <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                                <br/>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <b>{content.title}</b>
                                </Typography>
                                {content.chapters.map((chapter, chapter_id) => (
                                    <div key={chapter_id}>
                                        <b>Capítulo:</b> {chapter.title}
                                        <div className="video-list">
                                            {chapter.videos && chapter.videos.map((video, video_id) => (
                                                <div key={video_id}>
                                                    Aula:
                                                    <Button onClick={() => handleChangeVideo(video.url, video.title)}>
                                                        {video.title || `Video ${video_id + 1}`}
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={9}>
                            <h1>{videoTitle}</h1>
                            <div style={{width: '100%', height: '0', paddingBottom: '56.25%', position: 'relative'}}>
                                <iframe
                                    style={{position: 'absolute', width: '100%', height: '100%', top: '0', left: '0'}}
                                    src={`https://www.youtube.com/embed/${urlVideo}`}
                                    title='teste'
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            <BannerLogo />
            <Footer/>
        </>
    );
}

export default CourseDetails;
