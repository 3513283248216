import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import AuthRequired from './components/context/AuthRequired';
import { AuthAction } from "./components/context/AuthContext";

// Componentes que não são autenticados
import Home from './pages/public/home/Home';
import Products from './components/products/Products';
import MyProducts from './pages/private/myproducts/MyProducts';
import Blog from './pages/public/blog/Blog';
import Newsletter from './pages/public/newsletter/Newsletter';
import Registry from './components/auth/Registry';
import Login from './pages/public/login/Login';
import Logout from './components/auth/Logout';
import NotFound from './NotFound';

// Componentes que são autenticados
import Checkout from './pages/private/checkout/Checkout';
import Dashboard from './pages/private/dashboard/Dashboard';
import Courses from './components/courses/Courses';
import MyCourses from './pages/private/myCourses/MyCourses';
import CourseDetails from './pages/private/myCourses/CourseDetails';
import MyNewsletter from "./pages/private/myNewsletter/MyNewsletter";
import Ebooks from './components/ebooks/Ebooks';
import MyEbooks from './pages/private/myEbooks/MyEbooks';
import MyProfile from './pages/private/myProfile/MyProfile';

export default function App() {
    return (
        <>
            <div className="App">
                <AuthAction>
                    <Routes>

                        {/* Rota de erro */}
                        <Route path='*' element={<NotFound />}/>

                        {/* Rotas não autenticadas */}
                        <Route path="/" element={<Home />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/registry" element={<Registry />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/newsletter" element={<Newsletter />} />

                        {/* Rotas autenticadas */}
                        <Route element={<AuthRequired />}>
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/ebooks" element={<Ebooks />} />
                            <Route path="/courses" element={<Courses />} />
                            <Route path="/mycourses" element={<MyCourses />} />
                            <Route path="/mycourses/:id" element={<CourseDetails />} />
                            <Route path="/myebooks" element={<MyEbooks />} />
                            <Route path="/mynewsletter" element={<MyNewsletter />} />
                            <Route path="/myproducts" element={<MyProducts />} />
                            <Route path="/myprofile" element={<MyProfile/>} />
                        </Route>
                    </Routes>
                </AuthAction>
            </div>
        </>
    );
}
