import React from 'react';
import './Linkbar.css';

export default function Linkbar() {
    return (
        <div className="linkbar">
            <ul>
                <li><a href="/dashboard" >Dashboard</a></li>
                <li><a href="/myproducts" >Produtos</a></li>
                <li><a href="/mynewsletter" >Newsletter</a></li>
                <li><a href="/mycourses" >Meus Cursos</a></li>
                <li><a href="/myebooks" >Meus eBooks</a></li>
            </ul>
        </div>
    );
}
