import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

export default function Course(props) {
      const [open, setOpen] = React.useState(false);
      const navigate = useNavigate(); // Hook para navegação

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

    const goToCourse = async (e) => {
        const id = e.target.id;
        const token = document.cookie.split('; ').find(row => row.startsWith('session=')).split('=')[1];

        const data = {
            product_id: props.course.id,
            type: id,
        };

        if (id === "free-course") {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/registry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {
                    window.open("/mycourses", "_self");
                } else {
                    console.error('Failed to register for course');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            handleClickOpen();
            navigate('/checkout', { state: { course: props.course } });
        }
    };

  return (
    <>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Realize o pagamento para receber o eBook"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você será redirecionado para uma nova pagina.<br />
          Utilizamos um plataforma segura para realizar o pagamento.<br />
          <br />
          <b>Depois de confirmado o pagamento, seu curso será liberado.</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>

    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt={props.course.name}
        height="auto"
        image={props.course.banner}
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            <b>{props.course.name}</b>
        </Typography>

        <Typography >
          {props.course.description}
        </Typography>
        {props.course.price === 0 ? (
            <Button id="free-course" size="small" onClick={goToCourse}>Se inscrever</Button>
          ) : (
            <Button id="paid-course" size="small" onClick={goToCourse}>Comprar por R$ {props.course.price}</Button>
          )}
      </CardContent>
    </Card>
    </>
  );
}
