import React from 'react';

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import Linkbar from "../../../components/linkbar/Linkbar";

import BannerLogo from '../../../components/bannerlogo/BannerLogo';

export default function Newsletter() {

    // get email from jwt
    const cookie = document.cookie.split('; ').find(row => row.startsWith('session='));
    const email = cookie ? JSON.parse(atob(cookie.split('=')[1].split('.')[1])).email : '';

    const handleSubmitAddNewsletter = async () => {
        if (!email) {
            alert('Por favor, insira um email');
            return;
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/v1/newsletters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                alert('Adicionado na Newsletter');
            } else {
                alert('Falha ao adicionar na Newsletter');
            }
        } catch (error) {
            console.error('Erro ao adicionar na Newsletter:', error);
            alert('Erro ao adicionar na Newsletter');
        }
    };

    const handleSubmitRemoveNewsletter = async () => {
        if (!email) {
            alert('Por favor, insira um email');
            return;
        }
        try {
            const response = await fetch('https://api.lideratech.com.br/api/v1/newsletters', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                alert('Removido da Newsletter');
            } else {
                alert('Falha ao remover da Newsletter');
            }
        } catch (error) {
            console.error('Erro ao remover da Newsletter:', error);
            alert('Erro ao remover da Newsletter');
        }
    };

    return (
        <>
            <Header />
            <Linkbar />
            <Container>
                <Box mt={4} mb={4} display="flex" flexDirection="column" alignItems="center">
                    <Paper elevation={3} style={{ padding: '2em', maxWidth: '500px', width: '100%' }}>
                        <Typography variant="h4" component="h1" gutterBottom align="center">
                            Newsletter
                        </Typography>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            value={email}
                            style={{ marginBottom: '1em' }}
                            // disable edit field
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitAddNewsletter}
                                    fullWidth
                                >
                                    Subscribe
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleSubmitRemoveNewsletter}
                                    fullWidth
                                >
                                    UnSubscribe
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>

            <BannerLogo />
            <Footer />
        </>
    );
}
