import * as React from 'react';
import "./Ebooks.css";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import MyMenu from '../mymenu/MyMenu';
import Footer from "../footer/Footer";
import BannerLogo from "../bannerlogo/BannerLogo";
import Button from "@mui/material/Button";

export default function Ebooks() {

    const goToMyEbooks = () => {
        window.open("/myebooks");
    }

    return (
        <>
            <MyMenu />
            <Box sx={{ display: 'flex' }}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 4, // Increased padding
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto',
                                    }}
                                >
                                    <h1>eBooks</h1>
                                    <p>Aqui você encontra todos os nossos eBooks.</p>

                                    <Button variant="contained" onClick={goToMyEbooks}
                                            sx={{
                                                // size
                                                width: 300,
                                                // center
                                                margin: '0 auto',
                                                backgroundColor: '#4caf50',
                                                '&:hover': {
                                                    backgroundColor: '#388e3c',
                                                    opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}
                                    >
                                        Ir para Meus Ebooks
                                    </Button>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <br />
                                                <p>Nosso marketplace ainda não tem nenhum eBook a venda.</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <BannerLogo />
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </>
    );
}
