import * as Cookies from "js-cookie";


const removeCookies = () => {
    Cookies.remove("session");
    console.log("cookie removed");
};

export default function Logout() {
    removeCookies();
    window.location.href = "/";
}