import React from 'react';
import Grid from "@mui/material/Grid";

import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import MainFeaturedPost from "./MainFeaturedPost";
import Main from "./Main";

import BannerLogo from '../../../components/bannerlogo/BannerLogo';

import { Container } from '@mui/material';

const mainFeaturedPost = {
    title: 'Em um mar de informações, ajustar a bússula é fundamental.',
    description:
        "Com tantas coisas acontecendo e mudanças é natural nos sentirmos perdidos, por isso buscar direcionamento é importante.",
    image: 'https://source.unsplash.com/random?technology',
    imageText: 'main image description',
   // linkText: 'Leia Mais…',
};

const post1 = `

# Lançamento da Plataforma de Ensino LideraTech
### Junho 8, 2024 por LideraTech

É com grande entusiasmo que anunciamos o lançamento do nosso site oficial, [LideraTech](https://www.lideratech.com.br). Este é um marco significativo para a nossa empresa e estamos muito animados em compartilhar essa novidade com vocês.

A plataforma LideraTech foi criada com o objetivo de ser uma referência no ensino de tecnologia, oferecendo uma vasta gama de conteúdos que vão desde as tendências mais atuais até os fundamentos essenciais para qualquer desenvolvedor. Queremos ajudar a comunidade de tecnologia a evoluir e proporcionar um ambiente de aprendizado dinâmico e atualizado.

## O que você pode esperar da LideraTech?

### Cursos e Materiais Atualizados
Nossa plataforma contará com cursos e materiais atualizados sobre temas emergentes como Inteligência Artificial (AI), Metaverso, Realidade Aumentada e o Vision Pro da Apple. Além disso, abordaremos tópicos fundamentais e sempre relevantes como DDD, TDD, Clean Code, Clean Architecture e os princípios SOLID.

### Aprendizado Prático
Na LideraTech, acreditamos que a melhor forma de aprender é praticando. Por isso, nossos cursos são projetados para serem interativos e práticos, proporcionando aos alunos a oportunidade de aplicar o conhecimento adquirido em projetos reais.

### Comunidade Engajada
Acreditamos na força da comunidade. Nossa plataforma será um ponto de encontro para entusiastas da tecnologia, onde poderão trocar experiências, tirar dúvidas e se apoiar mutuamente no processo de aprendizado.

### Conteúdos Exclusivos e Atualizações Contínuas
Estamos comprometidos em oferecer conteúdos exclusivos e manter a plataforma sempre atualizada com as últimas tendências e melhores práticas do mercado. Queremos garantir que nossos alunos estejam sempre um passo à frente.

---

Estamos muito felizes com essa nova jornada e esperamos que a LideraTech possa ser um grande aliado no seu desenvolvimento profissional. Visite nosso site [LideraTech](https://www.lideratech.com.br) e comece hoje mesmo a explorar todo o conteúdo que preparamos com tanto carinho para você.

Juntos, vamos evoluir e transformar o mercado de tecnologia!


`

const posts = [post1];

export default function Blog() {
    return (
        <>
            <Header />
            <h1>Blog</h1>
            <Container maxWidth="lg">
                <main>
                    <MainFeaturedPost post={mainFeaturedPost}/>
                    <Grid container spacing={5} sx={{mt: 5}}>
                        <Main title="Noticias" posts={posts}/>
                    </Grid>
                </main>
            </Container>
            <BannerLogo />
            <Footer />
        </>
    )
}
